import usePasswordForm from "./UsePasswordForm";

import PasswordInput from "../../../../../common/components/inputs/PasswordInput";
import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import PasswordRequirementsList from "../../../../../common/components/inputs/PasswordRequirementsList";

const PasswordForm = ({ submitButtonText = 'Save', userName, onSubmitFormCallback }) => {
  const {
    formState,
    errorState,
    requirementsState,
    enableSaveButtonState,
    handleSubmit,
    updateFormState,
    onNewPasswordChanged,
    onClearFormClicked
  } = usePasswordForm(onSubmitFormCallback, userName);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <PasswordInput
            label='Current Password*'
            name="currentPassword"
            value={formState.currentPassword}
            error={errorState.currentPassword}
            message={errorState.currentPassword}
            onChange={(value) => { updateFormState('currentPassword', value) }} />
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <PasswordRequirementsList passwordRequirementsState={requirementsState} />
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <PasswordInput
            label='New Password*'
            name="newPassword"
            value={formState.newPassword}
            error={errorState.newPassword}
            message={errorState.newPassword}
            onChange={(value) => { onNewPasswordChanged(value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <PasswordInput
            label='Confirm New Password*'
            name="confirmPassword"
            value={formState.confirmPassword}
            error={errorState.confirmPassword}
            message={errorState.confirmPassword}
            onChange={(value) => { updateFormState('confirmPassword', value) }} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton isDisabled={enableSaveButtonState !== true} type="submit">{submitButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onClearFormClicked}>Cancel</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default PasswordForm;