import { useState } from 'react';

import validate from './ChangePasswordFormValidation';

import UseForm from "../../../../utils/UseForm";
import { PASSWORD_REQUIREMENTS, validatePasswordRequirements } from '../../../../utils/validation';

const INITIAL_FORM_STATE = {
  recoveryCode: '',
  newPassword: '',
  confirmPassword: ''
};

const INITIAL_REQUIREMENTS_STATE = {
  ...PASSWORD_REQUIREMENTS
};

const useChangePasswordForm = (onSubmitFormCallback, userName) => {
  const { formState, errorState, handleSubmit, updateFormState,
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);
  const [requirementsState, setRequirementsState] = useState(INITIAL_REQUIREMENTS_STATE);
  const [enableSaveButtonState, setEnableSaveButtonState] = useState(false);

  const onNewPasswordChanged = (value) => {
    const [isPasswordValid, passwordRequirements] = validatePasswordRequirements(value, userName);

    setRequirementsState({ ...passwordRequirements });
    setEnableSaveButtonState(isPasswordValid);
    updateFormState('newPassword', value);
  };

  return {
    formState,
    errorState,
    requirementsState,
    enableSaveButtonState,
    handleSubmit,
    updateFormState,
    onNewPasswordChanged
  };
};

export default useChangePasswordForm;