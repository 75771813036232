import { useState } from 'react';

import validate from './PasswordFormValidation';

import UseForm from "../../../../../common/utils/UseForm";
import { PASSWORD_REQUIREMENTS, validatePasswordRequirements } from '../../../../../common/utils/validation';

const INITIAL_FORM_STATE = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: ''
};

const INITIAL_REQUIREMENTS_STATE = {
  ...PASSWORD_REQUIREMENTS
};

const usePasswordForm = (onSubmitFormCallback, userName) => {
  const { formState, errorState, handleSubmit, updateFormState, setFormState, setErrors
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);
  const [requirementsState, setRequirementsState] = useState(INITIAL_REQUIREMENTS_STATE);
  const [enableSaveButtonState, setEnableSaveButtonState] = useState(false);

  const onNewPasswordChanged = (value) => {
    const [isPasswordValid, passwordRequirements] = validatePasswordRequirements(value, userName);

    setRequirementsState({ ...passwordRequirements });
    setEnableSaveButtonState(isPasswordValid);
    updateFormState('newPassword', value);
  };

  const onClearFormClicked = () => {
    setFormState({ ...INITIAL_FORM_STATE });
    setRequirementsState({...INITIAL_REQUIREMENTS_STATE});
    setEnableSaveButtonState(false);
    setErrors({});
  };

  return {
    formState,
    errorState,
    requirementsState,
    enableSaveButtonState,
    handleSubmit,
    updateFormState,
    onNewPasswordChanged,
    onClearFormClicked
  };
};

export default usePasswordForm;