import { useEffect, useState } from "react";

import validate from './UserInfoFormValidation';

import UseForm from "../../../../../../../common/utils/UseForm";
import Constants from "../../../../../../../common/utils/Constants";
import { PASSWORD_REQUIREMENTS, validatePasswordRequirements } from "../../../../../../../common/utils/validation";

const INITIAL_FORM_STATE = {
  firstName: '',
  lastName: '',
  preferredName: '',
  middleName: '',
  email: '',
  birthDate: Constants.BLANK_DATE_STRING,
  memberId: '',
  password: '',
  confirmPassword: ''
};

const INITIAL_REQUIREMENTS_STATE = {
  ...PASSWORD_REQUIREMENTS
};

const useUserInfoForm = (onSubmitFormCallback, userInfoData, childData) => {
  const { formState, errorState, isDirty, handleSubmit, updateFormState, setIsDirty, setFormData
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);
  const [requirementsState, setRequirementsState] = useState(INITIAL_REQUIREMENTS_STATE);
  const [enableSaveButtonState, setEnableSaveButtonState] = useState(false);

  const onPasswordChanged = (value) => {
    updateRequirementState(value, formState.email);
    updateFormState('password', value);
  };

  const onEmailChanged = (value) => {
    updateRequirementState(formState.password, value);
    updateFormState('email', value);
  };

  useEffect(() => {
    if (userInfoData) {
      setFormData({
        ...formState,
        firstName: childData?.firstName || userInfoData.firstName || '',
        lastName: childData?.lastName || userInfoData.lastName || '',
        preferredName: childData?.preferredName || userInfoData.preferredName || '',
        middleName: childData?.middleName || userInfoData.middleName || '',
        birthDate: childData?.birthDate || userInfoData.birthDate || Constants.BLANK_DATE_STRING,
        memberId: childData?.memberId || userInfoData.memberId || '',
        email: userInfoData.email || '',
        password: userInfoData.password || '',
        confirmPassword: userInfoData.confirmPassword || ''
      }, true);

      updateRequirementState(userInfoData.password || '', userInfoData.email || '');
    } else if (childData) {
      setFormData({
        ...formState,
        firstName: childData.firstName || '',
        lastName: childData.lastName || '',
        preferredName: childData.preferredName || '',
        middleName: childData.middleName || '',
        birthDate: childData.birthDate || Constants.BLANK_DATE_STRING,
        memberId: childData.memberId
      }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isDirty !== true) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  function updateRequirementState(password, email) {
    const [isPasswordValid, passwordRequirements] = validatePasswordRequirements(password, email);

    setEnableSaveButtonState(isPasswordValid);
    setRequirementsState({ ...passwordRequirements });
  }

  return {
    formState,
    errorState,
    requirementsState,
    enableSaveButtonState,
    handleSubmit,
    updateFormState,
    onPasswordChanged,
    onEmailChanged
  };
};

export default useUserInfoForm;