import useUserInfoForm from "./UseUserInfoForm";

import Input from "../../../../../../../common/components/inputs/Input";
import ReadOnly from "../../../../../../../common/components/readOnly/ReadOnly";
import DatePicker from "../../../../../../../common/components/datepickers/DatePicker";
import PasswordInput from "../../../../../../../common/components/inputs/PasswordInput";
import PrimaryButton from "../../../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../../../common/components/buttons/SecondaryButton";
import PasswordRequirementsList from "../../../../../../../common/components/inputs/PasswordRequirementsList";

const UserInfoForm = ({ userInfoData, childData, submitButtonText = 'Continue', secondaryButtonText = 'Back', onSubmitFormCallback,
  onSecondaryButtonClicked }) => {
  const {
    formState,
    errorState,
    requirementsState,
    enableSaveButtonState,
    handleSubmit,
    updateFormState,
    onPasswordChanged,
    onEmailChanged,
  } = useUserInfoForm(onSubmitFormCallback, userInfoData, childData);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          {childData?.firstName
            ? (
              <ReadOnly
                label={'Child First Name'}
                name={'firstName'}
                value={formState.firstName} />
            ) : (
              <Input
                label={'Legal First Name*'}
                name={'firstName'}
                value={formState.firstName}
                error={errorState.firstName}
                message={errorState.firstName}
                onChange={(value) => updateFormState('firstName', value)} />
            )
          }
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          {childData // Not needed for dup match so if childData is provided make readonly
            ? (
              <ReadOnly
                label={'Child Preferred Name'}
                name={'preferredName'}
                value={formState.preferredName} />
            ) : (
              <Input
                label={'Preferred Name'}
                name={'preferredName'}
                value={formState.preferredName}
                error={errorState.preferredName}
                message={errorState.preferredName}
                onChange={(value) => updateFormState('preferredName', value)} />
            )
          }
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          {childData // Not needed for dup match so if childData is provided make readonly
            ? (
              <ReadOnly
                label={'Child Middle Name'}
                name={'middleName'}
                value={formState.middleName} />
            ) : (
              <Input
                label={'Middle Name'}
                name={'middleName'}
                value={formState.middleName}
                error={errorState.middleName}
                message={errorState.middleName}
                onChange={(value) => updateFormState('middleName', value)} />
            )
          }
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          {childData?.lastName
            ? (
              <ReadOnly
                label={'Child Last Name'}
                name={'lastName'}
                value={formState.lastName} />
            ) : (
              <Input
                label={'Legal Last Name*'}
                name={'lastName'}
                value={formState.lastName}
                error={errorState.lastName}
                message={errorState.lastName}
                onChange={(value) => updateFormState('lastName', value)} />
            )
          }
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label={`Email* (This will also be your${childData ? ` child's` : ''} username)`}
            name={'email'}
            value={formState.email}
            error={errorState.email}
            message={errorState.email}
            onChange={(value) => onEmailChanged(value)} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          {childData?.birthDate
            ? (
              <ReadOnly
                label={'Child Birth Date'}
                name={'birthDate'}
                value={formState.birthDate} />
            ) : (
              <DatePicker
                label="Birth Date*"
                name="birthDate"
                value={formState.birthDate}
                error={errorState.birthDate}
                message={errorState.birthDate}
                onChange={(value) => updateFormState('birthDate', value)} />
            )
          }
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          {childData?.memberId
            ? (
              <ReadOnly
                label={'Child Member Id'}
                name={'memberId'}
                value={formState.memberId} />
            ) : (
              <Input
                label={'Member Id (Use to link to an existing person)'}
                name={'memberId'}
                value={formState.memberId}
                error={errorState.memberId}
                message={errorState.memberId}
                onChange={(value) => updateFormState('memberId', value)} />
            )
          }
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <PasswordRequirementsList passwordRequirementsState={requirementsState} displayUsernameAsEmail={true} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <PasswordInput
            label='Password*'
            name="password"
            value={formState.password}
            error={errorState.password}
            message={errorState.password}
            onChange={(value) => { onPasswordChanged(value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <PasswordInput
            label='Confirm Password*'
            name="confirmPassword"
            value={formState.confirmPassword}
            error={errorState.confirmPassword}
            message={errorState.confirmPassword}
            onChange={(value) => { updateFormState('confirmPassword', value) }} />
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton isDisabled={enableSaveButtonState !== true} type="submit">{submitButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type='button' onClick={() => onSecondaryButtonClicked(formState)}>{secondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default UserInfoForm;